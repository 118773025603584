import React, { useState, useEffect } from 'react';
import Api, { ACTIVITY_BASE_URL } from 'api';
import { Table } from 'components/Table';
import NoDataFound from 'components/NoDataFound';
import { Spinner2 } from 'components/Spinner2';
import { TableChangeState } from 'react-bootstrap-table-next';
import { IhdUserGeoInfo } from 'interfaces/Manager/IhdUserGeoInfo';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import i18n from 'translations';
import { expandRow } from './expandRow';
import { summaryColumns } from './summaryColumns';
import { getPageSize } from '../Settings';
import AggregationView from './AggregationView';

export const HyperdentUsersGeoData: React.FC = () => {
  const [state, setState] = useState({
    data: [],
    count: 0,
    page: 1,
    overview: {} as any,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize] = useState<number>(+getPageSize());

  const { data, count, page, overview } = state;

  const getPageData = (pageNum: number) => {
    return `/app/hdusers/data?_start=${
      (pageNum - 1) * pageSize
    }&_limit=${pageSize}`;
  };

  const fetchData = (pageNum: number) => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Api.get(getPageData(pageNum), {
          baseURL: ACTIVITY_BASE_URL,
        });
        setIsLoading(false);
        setState({
          count: res.count,
          data: res.data,
          page: pageNum,
          overview: res.overview,
        });
      } catch (error) {
        setIsLoading(false);
        console.log({
          info: 'Error while loading Hyperdent Users usage data.',
          error,
        });
      }
    })();
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleTableChange = (
    _type: any,
    changedState: TableChangeState<IhdUserGeoInfo>
  ) => {
    fetchData(changedState.page);
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">{i18n.t('hdUsageInfo.usageInfo')}</h1>
      {!isLoading && data.length > 0 && (
        <>
          <AggregationView overview={overview} />
          <Table
            rowStyle={{ verticalAlign: 'middle' }}
            data={data}
            columns={summaryColumns}
            page={page}
            sizePerPage={pageSize}
            totalSize={count}
            onTableChange={handleTableChange}
            expandRow={{ ...expandRow({ nonExpandable: data }) }}
          />
        </>
      )}
      {!isLoading && data.length === 0 && <NoDataFound />}
      <Spinner2 show={isLoading} />
    </ManagerLayout>
  );
};
