import React, { useEffect, useMemo } from 'react';
import { useGetDealerListQuery } from 'thunks/RTKQuery/dealer';
import { Spinner2 } from 'components/Spinner2';
import { DealerListView } from './DealersListView';
import { IDealerItem } from '../Models/dealers';

interface IDealerListViewContainer {
  manualRefetch: boolean;
  setIsUpdateMode: (mode: boolean) => void;
  setSelectedItem: (item: IDealerItem) => void;
  setErrorString: (err: string) => void;
}

export const DealerListViewContainer = ({
  manualRefetch,
  setIsUpdateMode,
  setSelectedItem,
  setErrorString,
}: IDealerListViewContainer) => {
  const params = 'id, name, dealerId';
  const {
    data: dealerData = [],
    error: dealerListError,
    isLoading: dealerListLoading,
    refetch: refetchDealerList,
  } = useGetDealerListQuery(params);

  useEffect(() => {
    if (dealerListError && 'message' in dealerListError) {
      setErrorString(`Error :${dealerListError.message}`);
    }
  }, [dealerListError]);

  useEffect(() => {
    if (manualRefetch) {
      refetchDealerList();
    }
  }, [manualRefetch]);

  const dealerList = useMemo(() => {
    const data = dealerData.slice();
    data.sort((a: any, b: any) => {
      return b.id - a.id;
    });
    return data;
  }, [dealerData]);

  const handleOnSelect = async (row: any) => {
    setIsUpdateMode(true);
    setSelectedItem({ id: row.id, name: row.name, dealerId: row.dealerId });
  };

  return (
    <>
      <Spinner2 show={dealerListLoading} />
      <DealerListView dealerData={dealerList} handleOnSelect={handleOnSelect} />
    </>
  );
};
