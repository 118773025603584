import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useAppDispatch } from 'store/hooks';
import {
  useAddNewDealerMutation,
  useGetOneDealerQuery,
  useUpdateDealerMutation,
} from 'thunks/RTKQuery/dealer';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components/Spinner2';
import { isFetchBaseQueryError } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { dealerInitialState, IDealerDetailState } from 'slices/dealer-details';
import { CONFIGURATION_TYPE } from 'constants/constants';
import { isValidJson } from 'utils/common';
import { FormikHelpers } from 'formik';
import { IDealerObject, IValidationDealer } from '../Models/dealers';
import { DealersDetailView } from './DealersDetailView';

interface IDealerDetailContainer {
  isUpdateMode: boolean;
  selectedId: number;
  setErrorString: (err: string) => void;
  setIsUserExist?: (val: boolean) => void;
  setManualRefetch?: (val: boolean) => void;
}

const schema: yup.SchemaOf<IValidationDealer> = yup.object().shape({
  dealerId: yup.string().required(),
  name: yup.string().required(),
  configurationType: yup.string().required(),
  fixedConfigJson: yup.string().when('configurationType', {
    is: CONFIGURATION_TYPE.FIXED_JSON,
    then: yup.string().test('is-json', 'Invalid JSON', isValidJson),
  }),
});

export const DealersDetailViewContainer = ({
  isUpdateMode,
  selectedId,
  setErrorString,
  setIsUserExist,
  setManualRefetch,
}: IDealerDetailContainer) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [dataSet, setDataSet] = useState<IDealerDetailState>(
    dealerInitialState
  );

  const [imageUrl, setImageUrl] = useState<string>('');

  const {
    data: detailData,
    isLoading: dealerDetailLoading,
    error: dealerDetailError,
  } = useGetOneDealerQuery(
    selectedId,
    selectedId === 0
      ? { skip: true, refetchOnMountOrArgChange: true }
      : { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!detailData) return;
    setImageUrl(
      detailData && detailData.logo ? detailData?.logo?.url : undefined
    );
    const fixedConfigJson =
      detailData && detailData.fixedConfigJson
        ? JSON.stringify(detailData.fixedConfigJson)
        : '';
    const configData = { ...detailData, fixedConfigJson };
    setDataSet({ data: configData });
    if (setIsUserExist) {
      setIsUserExist(!!detailData?.userId);
    }
  }, [detailData]);

  const [
    addNewDealer,
    {
      isLoading: dealerSaveLoading,
      error: dealerSaveError,
      status: dealerSaveStatus,
    },
  ] = useAddNewDealerMutation();
  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useUpdateDealerMutation();

  useEffect(() => {
    if (dealerDetailError && 'message' in dealerDetailError) {
      setErrorString(`Error :${dealerDetailError.message}`);
    }
    if (dealerSaveError && isFetchBaseQueryError(dealerSaveError)) {
      const msg = dealerSaveError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
  }, [dealerDetailError, dealerSaveError, dealerUpdateError]);

  useEffect(() => {
    if (!isUpdateMode) {
      setDataSet(dealerInitialState);
      setImageUrl('');
    }
  }, [isUpdateMode]);

  const handleSubmit = async (
    values: IDealerObject,
    formik: FormikHelpers<IDealerObject>
  ) => {
    setErrorString('');
    if (!isUpdateMode) {
      await addNewDealer(values);
    } else {
      await updateDealer(values);
    }
    formik.resetForm({ values: { ...values } });
  };

  useEffect(() => {
    let refetch = false;
    if (dealerSaveStatus === QueryStatus.fulfilled && !dealerSaveError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.save.title'),
          message: t('dealer.save.message'),
        })
      );
      refetch = true;
    }
    if (dealerUpdateStatus === QueryStatus.fulfilled && !dealerUpdateError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.update.title'),
          message: t('dealer.update.message'),
        })
      );
      refetch = true;
    }
    if (setManualRefetch) {
      setManualRefetch(refetch);
    }
  }, [dealerSaveStatus, dealerUpdateStatus]);

  return (
    <>
      <Spinner2
        show={dealerDetailLoading || dealerUpdateLoading || dealerSaveLoading}
      />
      <DealersDetailView
        detailData={dataSet.data}
        imageUri={imageUrl}
        validationSchema={schema}
        onSubmit={handleSubmit}
      />
    </>
  );
};

DealersDetailViewContainer.defaultProps = {
  setIsUserExist: () => {},
  setManualRefetch: () => {},
};
