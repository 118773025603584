import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { dealerApi } from 'thunks/RTKQuery/dealer';
import { userApi } from 'thunks/RTKQuery/user';
import {
  authSlice,
  toastSlice,
  signinSlice,
  signupSlice,
  registerSlice,
  confirmationSlice,
  forgotSlice,
  resetSlice,
  clientsSlice,
  ordersSlice,
  componentSlice,
  messagesSlice,
  companySlice,
  usersSlice,
  contactSlice,
  downloadAppSlice,
  previewLandingPageSlice,
  millSlice,
  clientSlice,
  responsiblePersonsSlice,
  labSlice,
  contractTypeSlice,
  paymentsSlice,
  appVersionSlice,
  licenseKeySlice,
  activePolicyDocSlice,
  userConsentSlice,
  licenseContentSlice,
  userDetailSlice,
} from '../slices';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    register: registerSlice.reducer,
    toast: toastSlice.reducer,
    signin: signinSlice.reducer,
    signup: signupSlice.reducer,
    confirmation: confirmationSlice.reducer,
    forgot: forgotSlice.reducer,
    reset: resetSlice.reducer,
    clients: clientsSlice.reducer,
    orders: ordersSlice.reducer,
    component: componentSlice.reducer,
    messages: messagesSlice.reducer,
    company: companySlice.reducer,
    users: usersSlice.reducer,
    contact: contactSlice.reducer,
    downloadApp: downloadAppSlice.reducer,
    preview: previewLandingPageSlice.reducer,
    mill: millSlice.reducer,
    lab: labSlice.reducer,
    client: clientSlice.reducer,
    responsiblePersons: responsiblePersonsSlice.reducer,
    contractTypes: contractTypeSlice.reducer,
    payments: paymentsSlice.reducer,
    appVersion: appVersionSlice.reducer,
    licenseKey: licenseKeySlice.reducer,
    activePolicies: activePolicyDocSlice.reducer,
    userConsent: userConsentSlice.reducer,
    licenseContent: licenseContentSlice.reducer,
    userDetails: userDetailSlice.reducer,
    [dealerApi.reducerPath]: dealerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware().concat(logger) &&
        getDefaultMiddleware().concat(dealerApi.middleware) &&
        getDefaultMiddleware().concat(userApi.middleware)
      : getDefaultMiddleware().concat(dealerApi.middleware) &&
        getDefaultMiddleware().concat(userApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
