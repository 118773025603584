import { CONFIGURATION_TYPE } from 'constants/constants';
import { IApiState } from 'interfaces/api';
import { IDealerObject } from 'pages/Manager/DealersManagement/Models/dealers';

export interface IDealerDetailState extends IApiState {
  data: IDealerObject;
}

const initialContactData = {
  id: 0,
  zipCode: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  email: '',
  webUrl: '',
  address1: '',
  address2: '',
  address3: '',
  district: '',
};

const dealerFiles = {
  logo: '',
  configFile: '',
};

export const dealerInitialState: IDealerDetailState = {
  data: {
    id: 0,
    dealerId: '',
    name: '',
    parentDealerId: '',
    dealerContact: initialContactData,
    salesContact: initialContactData,
    supportContact: initialContactData,
    logo: '',
    files: dealerFiles,
    configurationType: CONFIGURATION_TYPE.NONE,
    fixedConfigJson: '',
    fixedConfigFile: [],
    dynamicConfigUrlPath: '',
    componentBaseURL: '',
    userId: 0,
  },
};
