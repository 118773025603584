import React, { useState } from 'react';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { authSlice } from 'slices';
import { InviteUsersContainer } from 'components/Modal/InviteUsers/InviteUsersContainer';
import { useTranslation } from 'react-i18next';
import { DealerListViewContainer } from './DealersListView/DealersListViewContainer';
import { DealersDetailViewContainer } from './DealersDetailView/DealersDetailViewContainer';
import { IDealerItem } from './Models/dealers';

export const DealersManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState<IDealerItem>();
  const [errorString, setErrorString] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const [isUserExist, setIsUserExist] = useState<boolean>(false);
  const [manualRefetch, setManualRefetch] = useState(false);
  const { t } = useTranslation();

  const createDealer = () => {
    setIsUpdateMode(false);
  };

  const inviteUser = () => {
    dispatch(authSlice.actions.showInviteUserModel());
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">{t('dealerManagement.heading')}</h1>
      <hr />

      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={4}>
          <div className="alert alert-error text-left font-size-sm">
            {errorString}
          </div>
        </Col>
        <Col md={2}>
          <Button
            disabled={!isUpdateMode || (isUpdateMode && isUserExist)}
            onClick={inviteUser}
            className="btn-outline-primary invite-button"
          >
            {t('dealerManagement.invitation')}
          </Button>
        </Col>
        <Col md={4} />

        <Col md={2}>
          <Button
            onClick={createDealer}
            className="btn-outline-primary create-button"
          >
            {t('dealerManagement.newUser')}
          </Button>
        </Col>
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={4} className="mr-20">
          <DealerListViewContainer
            manualRefetch={manualRefetch}
            setIsUpdateMode={setIsUpdateMode}
            setSelectedItem={setSelectedItem}
            setErrorString={setErrorString}
          />
        </Col>
        <Col md={8}>
          <DealersDetailViewContainer
            isUpdateMode={isUpdateMode}
            selectedId={selectedItem?.id ?? 0}
            setErrorString={setErrorString}
            setIsUserExist={setIsUserExist}
            setManualRefetch={setManualRefetch}
          />
        </Col>
        <InviteUsersContainer selectedItem={selectedItem!} />
      </div>
    </ManagerLayout>
  );
};
