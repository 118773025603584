import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IhdUserGeoInfo } from 'interfaces/Manager/IhdUserGeoInfo';
import moment from 'moment';
import i18n from 'translations';

export const summaryColumns: ColumnDescription<any, IhdUserGeoInfo>[] = [
  {
    dataField: 'licenseKey',
    text: i18n.t('hdUsageInfo.licenseKey'),
  },
  {
    dataField: 'maxNetworkUsers',
    text: i18n.t('hdUsageInfo.allowedUsers'),
  },
  {
    dataField: 'multipleLogins',
    text: i18n.t('hdUsageInfo.mulipleLogins'),
    formatter: (_cell: any, row: IhdUserGeoInfo) => {
      let isTrue;

      if (row.multipleLogins && row.multipleLogins.length) {
        isTrue =
          row.multipleLogins[row.multipleLogins.length - 1].isConcurrentlyUsed;
      }

      return (
        <div style={{ backgroundColor: isTrue ? 'lightcoral' : '' }}>
          {' '}
          {isTrue ? 'TRUE' : 'FALSE'}{' '}
        </div>
      );
    },
  },
  {
    dataField: 'created_at',
    text: i18n.t('hdUsageInfo.firstPing'),
    formatter: (_cell: any, row: IhdUserGeoInfo) => {
      return moment(row.created_at).format('DD.MM.YYYY HH:mm');
    },
  },
];
