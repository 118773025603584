import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import i18n from 'translations';

export default function AggregationView({ overview }: any) {
  const lastUpdateAt = overview?.lastUpdateAt
    ? moment(overview.lastUpdateAt).format('DD.MM.YYYY HH:mm:ss')
    : '--';

  return (
    <Row className="mt-20 ml-12" style={{ backgroundColor: '#fa960021' }}>
      <Col className="ml-4">
        <strong>{i18n.t('hdUsageInfo.runningMachines')}: </strong>
        {overview?.isRunning}
      </Col>
      <Col className="ml-4">
        <strong>{i18n.t('hdUsageInfo.concurrentUsers')}: </strong>
        {overview?.concurrentlyUsedTickets}
      </Col>
      <Col className="ml-4">
        <strong>{i18n.t('hdUsageInfo.lastUpdateReceivedAt')}: </strong>
        {lastUpdateAt}
      </Col>
    </Row>
  );
}
